import { Dropdown } from 'bootstrap';

function load_sidebar() {
  const none = 'd-none';
  const block = 'd-block';
  const sidebar = document.getElementById('sidebar');
  const sidebarSm = document.getElementById('sidebarSm');
  const body = document.body;
  const collapseContainer = document.getElementById('collapseSidebar');
  const button = document.getElementById('collapseSidebarButton'); // The sidebar collapse button

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
  };

  const toggleSidebar = () => {
    if (!sidebar || !sidebarSm || !body) return;

    const isSidebarHidden = sidebar.classList.contains(none);
    sidebarSm.classList.toggle(none, isSidebarHidden);
    sidebar.classList.toggle(none, !isSidebarHidden);
    body.classList.toggle('sidebar-sm', !isSidebarHidden);
    body.classList.toggle('sidebar-full', isSidebarHidden);
    button.innerHTML = isSidebarHidden ? '<i class="fe fe-chevron-left"></i>' : '<i class="fe fe-chevron-right"></i>';
    setCookie('use_small_sidebar', isSidebarHidden ? 'false' : 'true', 7);
  };

  const initializeCollapseSidebarButton = () => {
    if (button) {
      button.addEventListener('click', toggleSidebar);

      collapseContainer.addEventListener('mouseenter', () => {
        button.classList.add('show-collapse-button');
      });

      collapseContainer.addEventListener('mouseleave', () => {
        button.classList.remove('show-collapse-button');
      });

      sidebar.addEventListener('mouseenter', () => {
        button.classList.add('show-collapse-button');
      });

      sidebar.addEventListener('mouseleave', () => {
        button.classList.remove('show-collapse-button');
      });

      sidebarSm.addEventListener('mouseenter', () => {
        button.classList.add('show-collapse-button');
      });

      sidebarSm.addEventListener('mouseleave', () => {
        button.classList.remove('show-collapse-button');
      });
    }
  };

  const handleViewportChange = (e) => {
    const isWideViewport = e.matches;
    const isSidebarSm = body.classList.contains('sidebar-sm');

    if (!sidebar || !sidebarSm) return;

    if (isWideViewport) {
      sidebar.classList.toggle(none, isSidebarSm);
      sidebarSm.classList.toggle(none, !isSidebarSm);
    } else {
      sidebarSm.classList.add(none);
      sidebar.classList.remove(none);
      sidebar.classList.add(block);
    }
  };

  document.querySelectorAll('[data-bs-toggle="navdropdown"]').forEach((dropdown) => {
    const instance = new Dropdown(dropdown);

    // Toggle the dropdown when the button is clicked
    dropdown.addEventListener('click', (e) => {
      e.preventDefault();
      instance.toggle();
    });
  });

  const mediaQuery = window.matchMedia('(min-width: 768px)');
  mediaQuery.addEventListener('change', handleViewportChange);
  handleViewportChange(mediaQuery);

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initializeCollapseSidebarButton);
  } else {
    initializeCollapseSidebarButton();
  }
}

window.addEventListener('ready', load_sidebar);
window.addEventListener('turbolinks:load', load_sidebar);
