tinymce.PluginManager.add('aisuggest', function(editor, url) {
  var openDialog = function () {
    return editor.windowManager.open({
      title: 'AI Content Generation',
      body: {
        type: 'panel',
        items: [
          {
            type: 'textarea',
            name: 'prompt',
            label: 'Prompt',
            placeholder: 'Write me an article about...'
          },
          {
            type: 'alertbanner',
            text: 'This feature is in beta. Text generation will take several seconds to complete. Please provide any feedback to Support or Customer Success.',
            level: 'info',
            icon: 'warning'
          }
        ]
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Close'
        },
        {
          type: 'submit',
          text: 'Generate',
          primary: true
        }
      ],
      onSubmit: function (api) {
        var data = api.getData();
        console.log(data)
        fetch(`/${process.env.PATH_PREFIX}/api/v1/ai/suggestion.json`,
         {
            method: 'POST',
            body: JSON.stringify({ message: data.prompt }),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            credentials: 'include'
          }).then(
            response => {
              response.json().then(data => {
                console.log(data)
                editor.insertContent(data.choices[0]?.message?.content?.replace(/\n/g, '<br/>'));
              })
            }
          )
        /* Insert content when the window form is submitted */
        // editor.insertContent('Title: ' + data.title);
        api.close();
      }
    });
  };
  /* Add a button that opens a window */
  editor.ui.registry.addButton('aisuggest', {
    text: 'AI',
    onAction: function () {
      /* Open window */
      openDialog();
    }
  });
  /* Adds a menu item, which can then be included in any menu via the menu/menubar configuration */
  editor.ui.registry.addMenuItem('aisuggest', {
    text: 'Example plugin',
    onAction: function() {
      /* Open window */
      openDialog();
    }
  });
  /* Return the metadata for the help plugin */
  return {
    getMetadata: function () {
      return  {
        name: 'AI content suggestion',
        url: 'http://exampleplugindocsurl.com'
      };
    }
  };
});
