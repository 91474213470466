import { Modal } from 'bootstrap';

function createModalHtml(
  id,
  icon,
  title,
  message,
  confirmButtonText,
  confirmButtonClass,
  cancelButtonText,
  cancelButtonClass
) {
  return `
      <div class="modal fade" id="${id}" tabindex="-1" role="dialog" aria-labelledby="${id}" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">${title}</h4>
            </div>
            <div class="modal-body py-4 px-3">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <i class="fe ${icon} pb-3" style="font-size: 72px;"></i>
                <p class="mb-0 text-center">${message}</p>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn ${cancelButtonClass}" data-bs-dismiss="modal">${cancelButtonText}</button>
              <button type="button" class="btn ${confirmButtonClass}" id="${id}-confirm">${confirmButtonText}</button>
            </div>
          </div>
        </div>
      </div>
    `;
}

function load_confirmation_dialog() {
  // Add event listeners to all buttons with the data-confirmation-dialog attribute
  const confirmationLinks = document.querySelectorAll('[data-confirmation-dialog-message]');

  confirmationLinks.forEach((element) => {
    // Assign data attributes to variables
    const id = element.dataset.confirmationDialogId;
    const icon = element.dataset.confirmationDialogIcon;
    const message = element.dataset.confirmationDialogMessage;
    const title = element.dataset.confirmationDialogTitle;
    const confirmButtonText = element.dataset.confirmationDialogConfirmButtonText;
    const confirmButtonClass = element.dataset.confirmationDialogConfirmButtonClass;
    const cancelButtonText = element.dataset.confirmationDialogCancelButtonText;
    const cancelButtonClass = element.dataset.confirmationDialogCancelButtonClass;

    element.addEventListener('click', (event) => {
      // Prevent the button from submitting the form instantly.
      event.preventDefault();

      // Check if the modal already exists in the DOM
      let modalDOMElement = document.getElementById(id);
      if (!modalDOMElement) {
        // If the modal is not in the DOM, create it
        document.body.insertAdjacentHTML(
          'beforeend',
          createModalHtml(id, icon, title, message, confirmButtonText, confirmButtonClass, cancelButtonText, cancelButtonClass)
        );
        modalDOMElement = document.getElementById(id);

        // Add the click event listener to the confirm button to manually handle the procedure
        document.getElementById(`${id}-confirm`).addEventListener('click', () => {
          // This is part of Rail's `button_to` helper, we call the parent <form> element to submit it
          element.closest('form').submit();
          confirmationModal.hide();
        });

        // Add event listener part of Bootstrap's Modal feature to remove the modal from the DOM when it's closed
        modalDOMElement.addEventListener('hidden.bs.modal', () => {
          modalDOMElement.remove();
        });
      }

      // Create a new instance of the Bootstrap Modal class and show it
      const confirmationModal = new Modal(modalDOMElement);
      confirmationModal.show();
    });
  });
}

window.addEventListener('ready', load_confirmation_dialog);
window.addEventListener('turbolinks:load', load_confirmation_dialog);
