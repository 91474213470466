// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import React from "react";
window.react = React;

var componentRequireContext = require.context("components", true);
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.useContext(componentRequireContext);

window.Turbolinks = Turbolinks;
ReactRailsUJS.detectEvents();

// Theme
import './theme/theme';
import './src/dynamic_add';
import './src/dynamic_hidden_fields';
import './src/google_places_autocomplete';
import './src/job_url_builder';
import './src/category_value_url_builder';
import './src/tinymce';
import './src/site_attributes_builder';
import './src/localisedTime';
import './src/instant_modal';
import './src/confirmation_dialog';
import './src/sidebar';


