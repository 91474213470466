// This allows you to have sections of HTML that shows or hides depending on the values chosen for an input.
// If the input changes to having a value in the whitelist, then the panel is shown.
// If the value changes to one not in the whitelist, then the panel is hidden and inputs disabled for safety.
// Attributes to enable this as follows. If the panel should start hidden then the initial .d-none class should be added by the view.
// <div class="dynamic-hidden-fields" data-allowed-values="["single-select", "multi-select"]" data-watched-input="entity[data_type]">
function load_dynamic_hidden_fields(){
  const hidden_panels = document.querySelectorAll('.dynamic-hidden-fields');
  hidden_panels.forEach((panel) => {
    const watched_input = panel.dataset.watchedInput; // name of input
    const watched_form = panel.dataset.watchedForm ? document.querySelector(panel.dataset.watchedForm) : panel.closest('form'); // selector for form that has the input

    const allowed_values = JSON.parse(panel.dataset.allowedValues); // whitelist of values we are looking for

    let tested_input = watched_form[watched_input];

    // this handles checkbox inputs that also have a matching 'falsey' hidden input added by rails for deselection
    if(RadioNodeList.prototype.isPrototypeOf(tested_input)){
      tested_input = Array.prototype.slice.call(watched_form[watched_input]).find(e => e.type != 'hidden');
    }

    var onChange = function () {
      if(allowed_values.includes(tested_input.value) || (allowed_values.includes('checked') && tested_input.checked) ){
        // show the extra content if correct value selected
        panel.classList.remove('d-none');
        panel.querySelectorAll('input,select').forEach((input) => {
          input.disabled = false;
        });
      } else {
        // hide if wrong value, disable inputs to stop incorrect modification
        panel.classList.add('d-none');
        panel.querySelectorAll('input,select').forEach((input) => {
          input.disabled = true;
        });
      }
    };
    watched_form.addEventListener('change', onChange);
    onChange();
  });
}

window.addEventListener('ready', load_dynamic_hidden_fields);
window.addEventListener('turbolinks:load', load_dynamic_hidden_fields);