/*
This script populates a URL slug input on the job form based on the values that are entered into the title and reference
fields. The user is free to change the URL later, but this provides them a good starting point.

If the job is persisted then this code does not run to prevent clobbering the value which may be driving many links.
*/

class JobUrlBuilder {
  constructor(){
    this.input = document.querySelector('input#job_url_slug.unset');

    if(this.input == null){ return }; // input contains a persisted value so we will not modify it

    this.job_title_input = document.querySelector('input#job_title');
    this.job_reference_input = document.querySelector('input#job_external_reference');

    this.job_title_input.addEventListener('input', this.updateJobUrl.bind(this));
    this.job_reference_input.addEventListener('input', this.updateJobUrl.bind(this));
  }

  updateJobUrl(){
    // start with the job reference
    let new_url = this.job_reference_input.value

    // add a joiner if required
    if(new_url.length > 0){
      new_url += '-';
    }

    // add the job title
    new_url += this.job_title_input.value.toLowerCase();

    // naively sanitise, the user can always correct it
    new_url = new_url.replace(/[^a-zA-Z0-9-_]/g, '-').replace(/(\-{2,})/g, '-').replace(/(\-+)$/g, "");

    this.input.value = new_url;
  }
}

window.addEventListener('ready', (e) => { new JobUrlBuilder });
window.addEventListener('turbolinks:load', (e) => { new JobUrlBuilder });
