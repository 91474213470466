//
// popover.js
// Theme module
//

import { Popover } from 'bootstrap';

function loadPopovers(){
  const popovers = document.querySelectorAll('[data-bs-toggle="popover"]');

  popovers.forEach(popover => {
    new Popover(popover);
  });
}

window.addEventListener('ready', loadPopovers);
window.addEventListener('turbolinks:load', loadPopovers);