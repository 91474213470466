/*
This script populates a URL slug input on the job form based on the values that are entered into the title and reference
fields. The user is free to change the URL later, but this provides them a good starting point.

If the job is persisted then this code does not run to prevent clobbering the value which may be driving many links.
*/

class SiteAttributesBuilder {
  constructor(){
    this.codebuild_input = document.querySelector('form#sa-new-site input#site_aws_codebuild_project_name');
    this.s3bucket_input = document.querySelector('form#sa-new-site input#site_aws_s3_bucket_name');
    this.domain_input = document.querySelector('form#sa-new-site input#site_domains_attributes_0_name');


    this.site_name_input = document.querySelector('input#site_name');

    if(this.codebuild_input != null){
      this.site_name_input.addEventListener('input', this.updateCodebuild.bind(this));
    }

    if(this.s3bucket_input != null){
      this.site_name_input.addEventListener('input', this.updateS3Bucket.bind(this));
    }

    if(this.domain_input != null){
      this.site_name_input.addEventListener('input', this.updateDomain.bind(this));
    }
  }

  updateCodebuild(){
    // start with the site name
    let new_url = this.site_name_input.value

    // naively sanitise, the user can always correct it
    new_url = new_url.replace(/[^a-zA-Z0-9-_]/g, '-').replace(/(\-{2,})/g, '-').toLowerCase();

    this.codebuild_input.value = new_url + '-deployment';
  }

  updateS3Bucket(){
    // start with the site name
    let new_url = this.site_name_input.value

    // naively sanitise, the user can always correct it
    new_url = new_url.replace(/[^a-zA-Z0-9-_]/g, '-').replace(/(\-{2,})/g, '-').toLowerCase();

    this.s3bucket_input.value = new_url + '-sourceflow';
  }

  updateDomain(){
    // start with the site name
    let new_url = this.site_name_input.value

    // naively sanitise, the user can always correct it
    new_url = new_url.replace(/[^a-zA-Z0-9-_]/g, '-').replace(/(\-{2,})/g, '-').toLowerCase();

    this.domain_input.value = new_url + '.sites.sourceflow.co.uk';
  }
}

window.addEventListener('ready', (e) => { new SiteAttributesBuilder });
window.addEventListener('turbolinks:load', (e) => { new SiteAttributesBuilder });
