// This feature enables 'add another' when dealing with form fields where a user can provide multiple instances
// of some inputs.
// It clones everything inside the fieldset element, using the last existing fieldset as a template. It then increments
// any identifiers on the inputs themselves to play nicely with Rails' `accepts_nested_attributes_for`
function load_dynamic_adds(){
  const dynFSs = document.querySelectorAll('.dynamic-fieldset');

  dynFSs.forEach((dynFS) => {
    const createButton = dynFS.querySelector('button.add');
    createButton.addEventListener("click", () => {
      // Clone the last row
      let nodeToClone = dynFS.querySelector('fieldset:last-of-type');
      let newFieldset = nodeToClone.cloneNode(true);

      // remove any elements that should not be cloned into the new element
      newFieldset.querySelectorAll('[data-do-not-clone]').forEach(element => {
        element.remove();
      });

      // increment fieldset index
      let lastId = nodeToClone.id;
      let newId = parseInt(lastId, 10) + 1;
      newFieldset.id = newId;

      let idRegex = new RegExp(lastId);

      // refresh the elements to new input values and incremented ids
      newFieldset.querySelectorAll('input,select').forEach(input => {
        if(input.type == 'checkbox' || input.type == 'radio'){
          input.checked = false;
        } else {
          input.value = null;
        }
        input.id = input.id.replace(idRegex, newId);
        input.name = input.name.replace(idRegex, newId);
        input.disabled = false;
      });

      // in case the cloned fieldset is one marked for destroy and was hidden
      newFieldset.classList.remove('d-none');

      // append to form
      dynFS.querySelector('.dynamic-fieldset-container').appendChild(newFieldset);
    });

    // if the delete button is pressed, flag the element as destroyed if appropriate
    dynFS.addEventListener("click", (event)=> {
      if(event.target.closest('.dynamic-fieldset-remove')) {
        var fieldset = event.target.closest('fieldset');
        var input = fieldset.querySelector('input[data-dynamic-destroy]');
        input.value = 1;
        fieldset.classList.add('d-none');
      }
    });
  });
}

window.addEventListener('ready', load_dynamic_adds);
window.addEventListener('turbolinks:load', load_dynamic_adds);
