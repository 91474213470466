tinymce.PluginManager.add('hr', function(editor, url) {
  editor.addCommand('InsertHorizontalRule', function () {
    editor.execCommand('mceInsertContent', false, '<hr />');
  });
  editor.ui.registry.addButton('hr', {
    text: 'hr',
    cmd: 'InsertHorizontalRule'
  });

  return {
    getMetadata: function () {
      return  {
        name: 'Insert Horizontal Rule',
        url: 'http://exampleplugindocsurl.com'
      };
    }
  };
})