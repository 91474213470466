import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/models/dom/model';

// plugins
import 'tinymce/plugins/accordion';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';

import './tinymce_plugins/chatbot';
import './tinymce_plugins/hr';

function load_tinymce(){
  tinymce.init({
    selector: '.wysiwyg',  // change this value according to your HTML
    menubar: false,
    skin: false,
    content_style: 'body { color: white; font-family: sans-serif; font-size: 15px; }',
    plugins: 'accordion aisuggest autolink code fullscreen hr image link lists media table',
    toolbar: 'undo redo | styles | bold italic forecolor removeformat | numlist bullist hr | table accordion image link media | alignleft aligncenter alignright alignjustify | aisuggest fullscreen code',
    media_poster: false,
    media_alt_source: false,
    convert_urls: false,
    link_class_list: [
      { title: 'None', value: '' },
      { title: 'Primary Colour Button', value: 'primaryBtn' },
      { title: 'Secondary Colour Button', value: 'secondaryBtn' },
      { title: 'Custom Style Button', value: 'customBtn' },
      { title: 'Custom Style Link', value: 'customLink' }
    ],
    sandbox_iframes: false,
  });
}

window.addEventListener('ready', load_tinymce);
window.addEventListener('turbolinks:load', load_tinymce);

// Make available globally
window.tinymce = tinymce;
