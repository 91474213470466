function load_instant_modals(){
  const modals = document.querySelectorAll('.modal.instant-modal');

  modals.forEach((modal) => {
    const myModal = new window.Modal(modal)

    myModal.show();
  });
}

window.addEventListener('ready', load_instant_modals);
window.addEventListener('turbolinks:load', load_instant_modals);
