//
// flatpickr.js
// Theme module
//

import flatpickr from 'flatpickr';

function load_flatpickr(){
  const toggles = document.querySelectorAll('[data-flatpickr]');

  toggles.forEach((toggle) => {
    const options = toggle.dataset.flatpickr ? JSON.parse(toggle.dataset.flatpickr) : {};

    const instance = flatpickr(toggle, options);

    if(toggle.dataset.flatpickrSubmit){
      // submit the nearest form on date selected
      // must use onClose because onChange fires when selecting the first date in a range
      instance.config.onClose.push(function(selectedDates, dateStr, instance){ instance.input.closest('form').submit() } );
    }
  });
}

window.addEventListener('ready', load_flatpickr);
window.addEventListener('turbolinks:load', load_flatpickr);

// Make available globally
window.flatpickr = flatpickr;
