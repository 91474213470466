class localisedTime {
  localisedMode = true

  constructor() {
    this.times = document.querySelectorAll('[data-localised-time]')
    this.displayTimes()
    this.attachClickHandlers()
  }

  attachClickHandlers() {
    this.times.forEach(element => {
      element.addEventListener('click', this.toggleMode.bind(this))
    })
  }

  displayTimes(mode) {
    this.times.forEach(element => {
      var d = new Date(Date.parse(element.dataset.originalTime));
      if (this.localisedMode) {
        element.textContent = d.toLocaleString()
      } else {
        element.textContent = d.toISOString()
      }
    });
  }

  toggleMode(){
    this.localisedMode = !this.localisedMode;
    this.displayTimes();
  }
}

window.addEventListener('ready', (e) => { new localisedTime });
window.addEventListener('turbolinks:load', (e) => { new localisedTime });
